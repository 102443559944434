@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Amiri:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
  padding: 0;
  margin: 0;
  font-family: 'Abhaya Libre' !important;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

:root {
  --toastify-font-family: "custom" !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Amiri' !important;
  font-weight: 700;
}

.customfont {
  font-family: 'Amiri', sans-serif !important;
}

.twoLineText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  /* This is the standard property */
  -webkit-box-orient: vertical;
  height: 2.8em;
  /* Adjust height based on your font-size and line-height */

}

.slick-prev {
  left: 30px;
  /* top: 200px; */
  /* background: rgb(227, 227, 227); */
  /*to notice it, is white*/
  z-index: 1;
  font-size: 32px;
}

.slick-next {
  right: 50px;
  font-size: 32px;
}

.slick-next::before,
.slick-prev::before {
  font-size: 32px;
}

.image-container {
  position: relative;
  display: inline-block;
}

.cart-count {
  position: absolute;
  top: -10px;
  /* Adjust as needed */
  left: 10px;
  /* Adjust as needed */
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 8px;
  font-size: 12px;
  /* Adjust as needed */
  /* font-weight: bold; */
}

#root {
  overflow-x: hidden;
}

/* .react-multi-carousel-list {
  width: 75%;
  justify-content: center;
  margin: 0 auto;
} */

.carousel-button-group{
  position: absolute;
}